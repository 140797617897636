import axios from 'axios'
var FormData = require('form-data')

export const healthCheck = date => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: 'https://school-support-ws.mpac.ca/health'
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const decodeToken = token => {
    let data = new FormData()
    data.append('token', token)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/decode-token',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

// admin

export const login = formInput => {
    let data = new FormData()
    data.append('username', formInput.username)
    data.append('password', formInput.password)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/login',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getLastUpdatedCompleted = token => {
    let data = new FormData()
    data.append('token', token)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-last-updated-completed',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const setLastUpdated = (date, userId) => {
    let data = new FormData()
    data.append('date', date)
    data.append('user_id', userId)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/set-last-updated',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getLatestUpdates = user_id => {
    let data = new FormData()
    data.append('user_id', user_id)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-latest-updates',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getInProgress = date => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: 'https://school-support-ws.mpac.ca/get-in-progress'
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updateInProgress = (userId, toBeUpdated, toBeRemoved) => {
    let data = new FormData()
    data.append('user_id', userId)
    data.append('to_be_updated', JSON.stringify(toBeUpdated))
    data.append('to_be_removed', JSON.stringify(toBeRemoved))

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/update-in-progress',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updateCompleted = values => {
    let data = new FormData()
    data.append('values', JSON.stringify(values))

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/update-completed',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getDownloads = (startDate, endDate, type) => {
    let data = new FormData()
    data.append('start_date', startDate)
    data.append('end_date', endDate)
    data.append('type', type)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-downloads',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getCSV = (startDate, endDate, type) => {
    let data = new FormData()
    data.append('start_date', startDate)
    data.append('end_date', endDate)
    data.append('type', type)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-csv',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getStats = () => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'GET',
            url: 'https://school-support-ws.mpac.ca/get-stats'
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

// email

export const sendResidentsEmail = (
    emailAddress,
    schoolSupportCd,
    address,
    hasBeenUpdated,
    language
) => {
    let data = new FormData()
    data.append('email_address', emailAddress)
    data.append('school_support_cd', schoolSupportCd)
    data.append('address', address)
    data.append('has_been_updated', hasBeenUpdated ? 'Y' : 'N')
    data.append('language', language)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/send-residents-email',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const resendEmail = (
    rollNum,
    emailAddress,
    language
) => {
    let data = new FormData()
    data.append('roll_num', rollNum)
    data.append('email_address', emailAddress)
    data.append('language', language)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/resend-email',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

// owner

export const getResidents = formInput => {
    let data = new FormData()
    data.append('roll_num', formInput.rollNum)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-residents',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const addResidentsToProperty = (residents, address) => {
    let data = new FormData()
    data.append('residents', JSON.stringify(residents))
    data.append('address', JSON.stringify(address))

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/add-to-property',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updatePropertyResidents = residents => {
    let data = new FormData()
    data.append('residents', JSON.stringify(residents))

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/update',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const deleteResidents = formInput => {
    let data = new FormData()
    data.append('residents', JSON.stringify(formInput))

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/delete',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getPropertyInfo = formInput => {
    let data = new FormData()
    data.append('roll_num', formInput.rollNum)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-property-info',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const setPropertyInfo = formInput => {
    let data = new FormData()
    data.append('email_address', formInput.email_address)
    data.append('area_occupied', formInput.area_occupied)
    data.append('roll_num', formInput.roll_num)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/set-property-info',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updateSSTransactions = isTenant => {
    let data = new FormData()
    data.append('is_tenant', isTenant)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: `https://school-support-ws.mpac.ca/update-ss-transactions`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

// tenant

export const getStreets = cty_mun => {
    let data = new FormData()
    data.append('cty_mun', cty_mun)
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-streets',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getStreetTypeCds = (cty_mun, street_name) => {
    let data = new FormData()
    data.append('cty_mun', cty_mun)
    data.append('street_name', street_name)
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-street-type-cds',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getStreetDirectionCds = (cty_mun, street_name, street_type_cd) => {
    let data = new FormData()
    data.append('cty_mun', cty_mun)
    data.append('street_name', street_name)
    data.append('street_type_cd', street_type_cd)
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-street-direction-cds',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getResident = resident => {
    let data = new FormData()
    data.append('resident', JSON.stringify(resident))
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-tenant',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getTenantSchoolSupport = (roll_num, ips_subordinate_id) => {
    let data = new FormData()
    data.append('roll_num', roll_num)
    data.append('ips_subordinate_id', ips_subordinate_id)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-tenant-school-support',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const addTenantToProperty = (residents, emailAddress) => {
    let data = new FormData()
    data.append('residents', JSON.stringify(residents))
    data.append('email_address', emailAddress)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/add-tenants-to-property',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updatePropertyResidentsTenant = (residents, emailAddress) => {
    let data = new FormData()
    data.append('residents', JSON.stringify(residents))
    data.append('email_address', emailAddress)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/update-tenant',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getAllTenants = ips_property_id => {
    let data = new FormData()
    data.append('ips_property_id', ips_property_id)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-all-tenants',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

// property

// export const getRollNum = formInput => {
//     let data = new FormData()
//     data.append('roll_num', formInput.rollNum)

//     return new Promise((resolve, reject) => {
//         axios({
//             method: 'POST',
//             url: 'https://school-support-ws.mpac.ca/get-roll-num',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Access-Control-Allow-Origin': '*'
//             },
//             data: data
//         })
//             .then(function (response) {
//                 resolve(response.data)
//             })
//             .catch(function (error) {
//                 reject(error)
//             })
//     })
// }

// export const getResidentsCount = id => {
//     return new Promise((resolve, reject) => {
//         axios({
//             method: 'GET',
//             url: `https://school-support-ws.mpac.ca/residents/${id}`
//         })
//             .then(function (response) {
//                 resolve(response.data)
//             })
//             .catch(function (error) {
//                 reject(error)
//             })
//     })
// }

export const getAddress = formInput => {
    let data = new FormData()
    data.append('roll_num', formInput.rollNum)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-address',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updateSchoolSupport = (residents, schoolSupportCd, id, residentType) => {
    let data = new FormData()
    data.append('residents', JSON.stringify(residents))
    data.append('school_support_cd', schoolSupportCd)
    data.append('id', id)
    data.append('resident_type', residentType)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/update-school-support',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getMunicipality = formInput => {
    let data = new FormData()
    data.append('cty_mun', formInput.cty_mun)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-municipality',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const getSchoolBoards = formInput => {
    let data = new FormData()
    data.append('cty_mun', formInput.cty_mun)

    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/get-school-boards',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updateDelta = formInput => {
    let data = new FormData()
    data.append('residents', JSON.stringify(formInput.residents))
    data.append('munAddress30', formInput.munAddress30)
    data.append('areaOccupied', formInput.areaOccupied)
    data.append('propertySchoolSupportCd', formInput.propertySchoolSupportCd)
    data.append('emailAddress', formInput.emailAddress)
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/update-delta',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}

export const updateSSTransactionsDim = formInput => {
    let data = new FormData()
    data.append('residents', JSON.stringify(formInput.residents))
    data.append('address', JSON.stringify(formInput.address))
    data.append('areaOccupied', formInput.areaOccupied)
    data.append('propertySchoolSupportCd', formInput.propertySchoolSupportCd)
    data.append('emailAddress', formInput.emailAddress)
    return new Promise((resolve, reject) => {
        axios({
            method: 'POST',
            url: 'https://school-support-ws.mpac.ca/update-ss-transactions-owner',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            data: data
        })
            .then(function (response) {
                resolve(response.data)
            })
            .catch(function (error) {
                reject(error)
            })
    })
}
